import React, { useState } from 'react';
import { IonToast, IonAlert, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonToggle } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab2.css';
import { useSwitchAPI } from '../hooks/switchAPI';
import { useCardAPI } from '../hooks/cardAPI';

const Tab2: React.FC = () => {
  const { setSwitch, openDoor } = useSwitchAPI();
  const { getCardInfos } = useCardAPI();
  const [checked, setChecked] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Alert</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Alert tab</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton onClick={() => setShowAlert1(true)} expand="block">Show Alert 1</IonButton>
        <IonButton onClick={() => setShowToast1(true)} expand="block">Show Toast 1</IonButton>
        <IonButton onClick={() => getCardInfos()} expand="block">Show Toast 1</IonButton>
        <IonItem>
            <IonLabel>Switch</IonLabel>
            <IonToggle checked={checked} onIonChange={e => setSwitch(e.detail.checked)} />
        </IonItem>
        
        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          header={'Alert'}
          subHeader={'Subtitle'}
          message={'This is an alert message.'}
          buttons={['OK']}
        />

        <IonToast
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="Présence requise"
          position="top"
          buttons={[
            {
              side: 'start',
              icon: 'start',
              text: 'Valider',
              handler: () => {
                console.log('Favorite clicked');
              }
            },
            {
              text: 'Refuser',
              role: 'cancel',
              handler: () => {
                console.log('Validation clicked');
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
