import React, { useState } from 'react'
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react'

class ItemCard extends React.Component<{title: string, subtitle: string, content: string}> {

    render() {
        return (
            <div>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>{this.props.title}</IonCardTitle>
                        <IonCardSubtitle>{this.props.subtitle}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {this.props.content}
                    </IonCardContent>
                </IonCard>
            </div>
        )
    }
}

export default ItemCard;