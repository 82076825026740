import axios from "axios";

export function useSwitchAPI() {
    const setSwitch = async (state: boolean) => {
        const url = 'https://shelly-5-eu.shelly.cloud/device/relay/control/';
        const dataForm = new FormData();
        dataForm.append('auth_key', 'MTU1NDV1aWQ171E1AEDFA418DEB18BC680F18A1A751D26549D1E401F9BFC4D79B6C608486FC2E25EBBF7411105D');
        dataForm.append('turn', state ? 'on' : 'off');
        dataForm.append('channel', '0');
        dataForm.append('id', '767bd8');
        
        const api_body = {
            auth_key: 'MTU1NDV1aWQ171E1AEDFA418DEB18BC680F18A1A751D26549D1E401F9BFC4D79B6C608486FC2E25EBBF7411105D',
            turn: state ? 'on' : 'off',
            channel: '0',
            id: '767bd8'
        };
        
        axios.post(url, dataForm)
            .then(res => {
                console.log(res);
                console.log(res.data);
        })
    };

    const openDoor = async () => {
        
    };

    /*const getSwitch = async () => {
        const url = 'https://shelly-5-eu.shelly.cloud/device/relay/0/';
        const dataForm = new FormData();
        dataForm.append('auth_key', 'MTU1NDV1aWQ171E1AEDFA418DEB18BC680F18A1A751D26549D1E401F9BFC4D79B6C608486FC2E25EBBF7411105D');
        dataForm.append('channel', '0');
        dataForm.append('id', '767bd8');
        
        const api_body = {
            auth_key: 'MTU1NDV1aWQ171E1AEDFA418DEB18BC680F18A1A751D26549D1E401F9BFC4D79B6C608486FC2E25EBBF7411105D',
            channel: '0',
            id: '767bd8'
        };
        
        axios.get(url, dataForm)
        .then(response => {
            console.log(response.data.created_at);
            const state = res.data.ison;
        }) 
    };*/

    return {
        setSwitch, openDoor
    };
}