import React, { useState } from 'react'
import { IonCard, IonButton } from '@ionic/react'
import QrReader from 'react-qr-reader'
import ItemCard from '../components/ItemCard'
import axios from "axios";
import { SSL_OP_NETSCAPE_REUSE_CIPHER_CHANGE_BUG } from 'constants';

class QRreader extends React.Component {

    constructor(props: any) {
        super(props);
        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);
        this.reset = this.reset.bind(this);
        this.getCardInfos = this.getCardInfos.bind(this);
    }
    
    state = {
        name: '',
        description: '',
        id: '',
        scan: false
    }

    handleScan(data: any) {
        const base_url = "https://pm.valais.cloud?i"
        console.log('QR Scanned')
        if (data) {
            if(data.length > base_url.length)
            {
                var url = data.substring(0,base_url.length)
                if(url == base_url)
                {
                    this.getCardInfos(data.substring(base_url.length+1, data.length))
                    console.log("New result: " + this.state.name);
                } else {
                    this.setState({
                        name: 'Unvalid URL'
                    })
                }
            }
            else {
                this.setState({
                    name: 'Unvalid URL'
                })
            }
        }
    }

    handleError(err: any) {
        console.log('QR Error')
        console.error(err)
    }

    reset() {
        this.setState({
            name: '',
            description: '',
            id: ''
        })
    }

    set_res(response: any) {
        let n = response.data[0].ItemName
        let d = response.data[0].ItemDescription

        console.log("CardInfo result: " + n);
        this.setState({
            name: n,
            description: d
        })
    }

    set_error(error: any) {
        console.log("CardInfo error: " + error);
    }

    set_then() {

    }

    getCardInfos(data: any) {
        const url = 'https://api.valais.cloud/item/' + data + '/376F67F7283F6A1FB5F9B4D2980BA5A78BC4380FC1C02750DEC74959FAF25552';
        let res = ''
        axios.get(url, /*{
        params: {
            query: data
        }
        }*/
        )
        .then((response) => this.set_res(response))
        .catch((error) => this.set_error(error))
        .then(() => this.set_then());
        this.setState({
            name: data
        })
    };

    render() {
        let html
        let reset_button
        if (this.state.name == '' ) {
            html = <QrReader delay={300} onError={this.handleError} onScan={this.handleScan} style={{ width: '100%' }} />
        } else {
            html = <ItemCard title={this.state.name} subtitle={this.state.id} content={this.state.description} />
            reset_button = <IonButton onClick={this.reset} expand="full">New scan</IonButton>
        }

        return (
            <div>
                {html}
                {reset_button}
            </div>
        )
    }
}

export default QRreader;