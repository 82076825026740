import axios from "axios";

export function useCardAPI() {

    const getCardInfos = async () => {
        const url = 'http://transport.opendata.ch/v1/locations';
        axios.get(url, {
        params: {
            query: "Sion"
        }
        })
        .then(function (response) {
            let res = response.data.stations[0].name
            console.log(res);
            return res
        })
        .catch(function (error) {
            console.log(error);
            return ''
        })
        .then(function () {
            console.log("GET command processed on the card API");
        });  
    };

    return {
        getCardInfos
    };
}